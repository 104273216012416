import {
  TALL, MEDIUM, SHORT,
} from '@/constants/heightMappingTableValues';
import productHeaders from '@/constants/productHeaders';

const {
  TYPE_DESIGNATION,
  PRODUCT_TYPE,
  MODEL,
  VOLTAGE,
  MANUFACTURER,
  LIGHT_SOURCE_TYPE,
  ENVIRONMENT,
  MOUNTING,
  DIMMING_PROTOCOL,
  AGENT_SUPPLIER,
  LOCATION,
  TAG,
} = productHeaders;

// Schedule Views
const CREATE_NEW_VIEW = 'Create New View';
const DESIGN_TEAM_VIEW = 'Design Team View';
const SPECIFIER_VIEW = 'Specifier View';
const SPECIFIER_VIEW_TYPE = 'specifier';
const SALES_AGENCY_VIEW = 'Sales Agency View';
const CONTRACTOR_VIEW = 'Contractor View';
const DISTRIBUTOR_VIEW = 'Distributor View';
const PROJECT_BUDGET_VIEW = 'Project Budget View';
const PROJECT_BUDGET_VIEW_TYPE = 'budget';
const BUDGET_VIEW_KEYWORDS = ['budget', 'cost', 'price'];
/**
 * @deprecated
 * @see schedule_view_and_fields_modifications flag
 */
const SHOP_DRAWINGS = 'Shop Drawings';
const SUBMITTALS = 'Submittals View';
const PROJECT_STATUS = 'Project Status';

// This is super annoying.
// Sustainability = Power Density but it was not migrated at all.
// Instead there is a frontend-only hack to change the display name.
// - see src/store/ScheduleVies/actions.js
const SUSTAINABILITY_VIEW = 'Sustainability View';
const SUSTAINABILITY_VIEW_TYPE = 'powerDensity';
const POWER_DENSITY_VIEW = 'Power Density View';
const POWER_DENSITY_VIEW_TYPE = SUSTAINABILITY_VIEW_TYPE;

// Schedule Views Panels
const BUSINESS_ANALYTIC = 'BusinessAnalytic';
const SORTABLE_FIELDS_PROJECTS = [PRODUCT_TYPE, MANUFACTURER, MODEL,
  TYPE_DESIGNATION, LIGHT_SOURCE_TYPE, MOUNTING, ENVIRONMENT, DIMMING_PROTOCOL,
  VOLTAGE, AGENT_SUPPLIER, LOCATION, TAG];
const SORTABLE_FIELDS_COLLECTIONS = [PRODUCT_TYPE, MANUFACTURER, MODEL, ENVIRONMENT];
const SORTABLE_BY_DEFAULT = [MODEL, VOLTAGE];
const SORTABLE_AS_ARRAY = [PRODUCT_TYPE, MANUFACTURER, LIGHT_SOURCE_TYPE, ENVIRONMENT,
  MOUNTING, DIMMING_PROTOCOL, AGENT_SUPPLIER, LOCATION, TAG];
const SORTABLE_BY_COEFFICIENT = [TYPE_DESIGNATION];
const SORTABLE_ACTIONS_WIDTH = 45;
const SORT_TOP = 'ASC';
const SORT_BOTTOM = 'DESC';
const SORTABLE_OPTIONS = {
  [SORT_BOTTOM]: {
    sortDefault: false,
    enabled: false,
  },
  [SORT_TOP]: {
    sortDefault: true,
    enabled: true,
  },
};
//default version project
const DEFAULT_PROJECT_VERSION = 'live-version';

const ORDERING_OF_VIEWS = [
  CREATE_NEW_VIEW,
  DESIGN_TEAM_VIEW,
  SPECIFIER_VIEW,
  SALES_AGENCY_VIEW,
  CONTRACTOR_VIEW,
  DISTRIBUTOR_VIEW,
  PROJECT_BUDGET_VIEW,
  SUSTAINABILITY_VIEW,
  SUBMITTALS,
  SHOP_DRAWINGS,
  PROJECT_STATUS,
];
const SPECIFIC_OF_VIEWS = {
  [CREATE_NEW_VIEW]: TALL,
  [SPECIFIER_VIEW]: TALL,
  [DESIGN_TEAM_VIEW]: TALL,
  [SALES_AGENCY_VIEW]: TALL,
  [PROJECT_BUDGET_VIEW]: SHORT,
  [SUSTAINABILITY_VIEW]: SHORT,
  [CONTRACTOR_VIEW]: TALL,
  [DISTRIBUTOR_VIEW]: SHORT,
  [SUBMITTALS]: TALL,
  [SHOP_DRAWINGS]: TALL,
  [PROJECT_STATUS]: MEDIUM,
};
const DATA_TEST_OF_VIEWS = {
  [CREATE_NEW_VIEW]: 'create_new_view',
  [SPECIFIER_VIEW]: 'specifier_view_button',
  [DESIGN_TEAM_VIEW]: 'design_team_view_button',
  [SALES_AGENCY_VIEW]: 'sales_agency_view_button',
  [PROJECT_BUDGET_VIEW]: 'project_budget_view_button',
  [SUSTAINABILITY_VIEW]: 'sustainability_view_button',
  [CONTRACTOR_VIEW]: 'contractor_view_button',
  [DISTRIBUTOR_VIEW]: 'distributor_view_button',
  [SUBMITTALS]: 'submittals_view_button',
  [SHOP_DRAWINGS]: 'shop_drawings_view_button',
  [PROJECT_STATUS]: 'project_status_view_button',
};
export {
  DEFAULT_PROJECT_VERSION,
  CREATE_NEW_VIEW,
  DESIGN_TEAM_VIEW,
  SPECIFIER_VIEW,
  SPECIFIER_VIEW_TYPE,
  SALES_AGENCY_VIEW,
  CONTRACTOR_VIEW,
  DISTRIBUTOR_VIEW,
  PROJECT_BUDGET_VIEW,
  PROJECT_BUDGET_VIEW_TYPE,
  BUDGET_VIEW_KEYWORDS,
  SUBMITTALS,
  SHOP_DRAWINGS,
  PROJECT_STATUS,
  SUSTAINABILITY_VIEW,
  SUSTAINABILITY_VIEW_TYPE,
  POWER_DENSITY_VIEW,
  POWER_DENSITY_VIEW_TYPE,
  ORDERING_OF_VIEWS,
  SPECIFIC_OF_VIEWS,
  BUSINESS_ANALYTIC,
  SORTABLE_FIELDS_PROJECTS,
  SORTABLE_FIELDS_COLLECTIONS,
  SORTABLE_BY_DEFAULT,
  SORTABLE_AS_ARRAY,
  SORT_TOP,
  SORT_BOTTOM,
  SORTABLE_BY_COEFFICIENT,
  SORTABLE_ACTIONS_WIDTH,
  SORTABLE_OPTIONS,
  DATA_TEST_OF_VIEWS,
};
