import {
  TALL, MEDIUM, SHORT,
} from '@/constants/heightMappingTableValues';
const DEFAULT_THUMBNAILS_SIZES = (url = '') => ({
  large: {
    height: 104,
    width: 235,
    url,
  },
  medium: {
    height: 92,
    width: 238,
    url,
  },
  small: {
    height: 60,
    width: 238,
    url,
  },
});
const THUMBNAILS_SIZE = {
  [TALL]: 'large',
  [MEDIUM]: 'medium',
  [SHORT]: 'small',
};
const THUMBNAILS_SIZE_ASC = [
  THUMBNAILS_SIZE[TALL],
  THUMBNAILS_SIZE[MEDIUM],
  THUMBNAILS_SIZE[SHORT],
];
const DEFAULT_FIELD_THUMBNAILS = (url = '') => {
  return {
    full: {
      height: 50,
      width: 50,
      url,
    },
    large: {
      height: 50,
      width: 50,
      url,
    },
    small: {
      height: 36,
      width: 35,
      url: false,
    },
  };
};
const ORIGINAL = 'original';

export {
  DEFAULT_THUMBNAILS_SIZES,
  THUMBNAILS_SIZE,
  DEFAULT_FIELD_THUMBNAILS,
  ORIGINAL,
  THUMBNAILS_SIZE_ASC,
};
