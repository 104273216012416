const TALL = 'Tall';
const MEDIUM = 'Medium';
const SHORT = 'Short';
const ROW_ADDITIONAL_STEP = 36;
const HEIGHT_OF_ROWS = {
  [TALL]: 164,
  [MEDIUM]: 88,
  [SHORT]: 56,
};
export {
  TALL,
  MEDIUM,
  SHORT,
  HEIGHT_OF_ROWS,
  ROW_ADDITIONAL_STEP,
};