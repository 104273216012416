import { isViewBudgetView } from '@/utils/scheduleViews';
import {
  BUSINESS_ANALYTIC,
  SORT_BOTTOM,
  SORT_TOP,
  SPECIFIC_OF_VIEWS,
  SUSTAINABILITY_VIEW,
} from '@/constants/scheduleViews';
import { TALL } from '@/constants/heightMappingTableValues';
export const getters = {
  selectedViewCriteria(state, getters) {
    const { selectedView } = getters;
    const findView = Object.keys(SPECIFIC_OF_VIEWS).find(view => selectedView?.name.includes(view));
    return SPECIFIC_OF_VIEWS[findView] || TALL;
  },
  selectedViewId(state) {
    return state.selectedView?.id;
  },
  selectedViewName(state, getters) {
    return getters.selectedView?.name;
  },
  selectedView(state, getters) {
    return getters.getSelectedViewById(state.selectedViewId);
  },
  tableHeaders(state, getters, rootState, rootGetters) {
    let { columnOrder = [] } = getters.selectedView ?? {
    };
    columnOrder = ['order', ...columnOrder];
    const getTableHeaderFromObj = rootGetters['ProjectDetailsTableSchedule/getTableHeaderFromObj'];
    return columnOrder.reduce((acc = [], column) => {
      const columnFound = getTableHeaderFromObj(column);
      if (!columnFound) return acc;
      acc.push(columnFound);
      return acc;
    }, []);
  },
  columnsSizeMapping: (state, getters) => {
    const { columnsSize = [] } = getters.selectedView ?? [];
    return columnsSize.reduce((result = {
    }, view) => {
      return {
        ...result,
        [view?.id]: {
          ...view,
        },
      };
    }, {
    });
  },
  stateViewsMapping: state => {
    return state.views.reduce((acc = {
    }, view) => ({
      ...acc,
      [view?.id]: view,
    }), {
    });
  },
  getSelectedRowHeight(state) {
    return state.selectedRowHeight;
  },
  getSelectedViewById: (state, getters) => (id) => {
    const { stateViewsMapping } = getters;
    const selectedView = stateViewsMapping[id];
    const columnOrder = selectedView?.customColumnOrder?.length ? selectedView?.customColumnOrder : selectedView?.columnOrder;
    return columnOrder ? {
      ...selectedView,
      columnOrder,
    } : selectedView;
  },
  selectedViewPanel(state, getters) {
    return getters.selectedView?.panel;
  },
  sortingData(state, getters) {
    return getters.selectedView?.sortingData;
  },
  isSortingBottom(state, getters) {
    return getters.sortingData?.sortOrder === SORT_BOTTOM;
  },
  isSortingTop(state, getters) {
    return getters.sortingData?.sortOrder === SORT_TOP;
  },
  viewsIds(state, getters) {
    const { stateViewsMapping } = getters;
    return Object.keys(stateViewsMapping);
  },
  isSelectedProjectBudgetView(state, getters) {
    return isViewBudgetView(getters.selectedView);
  },
  isSelectedSustainabilityView(state, getters) {
    const { selectedViewName: name, selectedViewPanel } = getters;
    return name?.includes(SUSTAINABILITY_VIEW) || selectedViewPanel === BUSINESS_ANALYTIC;
  },
  viewsDropDownsObj(state) {
    const { views } = state;
    if (!views.length) {
      return null;
    } else {
      const { custom_dropdowns, dropdowns } = views[0];
      return {
        custom_dropdowns,
        dropdowns,
      };
    }
  },
  selectedViewTag(state, getters) {
    const { selectedView } = getters;
    return selectedView?.filterTag;
  },
  customViews(state) {
    return state.views.filter(({ type }) => type === 'custom');
  },
  customViewsForExportToSchedule(state, getters) {
    const { customViews } = getters;
    const mappedViews = customViews.map(({ id, filterTag, name }) => ({
      id,
      filterTag,
      name,
    }));
    return mappedViews;
  },
};
