import { TALL } from '@/constants/heightMappingTableValues';

export const getDefaultState = () => {
  return {
    activeTags: [],
    selectedView: null,   // check everywhere this is being used and how it is being set
    selectedViewId: null,
    selectedRowHeight: TALL,
    views: [],
  };
};
export const state = getDefaultState();
