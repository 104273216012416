export const mutations = {
  setActiveTags(state, payload) {
    state.activeTags = payload;
  },
  // Avoid using this outside of the 'setSelectedViewId' action.
  // We need to sync the tags with it.
  setSelectedViewId(state, payload) {
    state.selectedViewId = payload;
  },
  setSelectedRowHeight(state, payload) {
    state.selectedRowHeight = payload;
  },
  setViews(state, payload) {
    state.views = payload;
  },
};
